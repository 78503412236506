import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';
import { useLoaderData, useParams } from "react-router-dom";
import Toolbar from '../components/questions/toolbar';
import EditQuestion from '../components/modals/edit-question';
import Modal from '../components/modal';
import Table from '../components/common/table';
import '../styles/questions.scss';

import { useVirtualizer } from '@tanstack/react-virtual'
import { useMediaQuery } from 'react-responsive'

const transparentGhostImage = document.createElement("img");   
transparentGhostImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export default function Questions({params}) {
  const isMobile = useMediaQuery({ query: '(max-width: 430px)' })

  const { questions: loaderQuestions, total: loaderTotal } = useLoaderData();
  const [questions, setQuestions] = useState(loaderQuestions);
  const [selectedFilterTags, setSelectedFilterTags] = useState([])
  const [total, setTotal] = useState(loaderTotal)
  const [sortDirection, setSortDirection] = useState('DESC')
  const [sortBy, setSortBy] = useState('updated_at')
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [currentModal, setCurrentModal] = useState(null)

  const columnWidths = JSON.parse(window.localStorage.getItem('trivaTownColumnWidths')) || {}
  const [titleWidth, setTitleWidth] = useState(isMobile ? '100%' : columnWidths.title || 450)
  const [answerWidth, setAnswerWidth] = useState(isMobile ? '100%' : columnWidths.answer|| 300)
  const [tagsWidth, setTagsWidth] = useState(isMobile ? '100%' : columnWidths.tags|| 200)
  const [createdDateWidth, setCreatedDateWidth] = useState(isMobile ? '100%' : columnWidths.createdDate|| 112)
  const [createdByWidth, setCreatedByWidth] = useState(isMobile ? '100%' : columnWidths.createdBy|| 200)

  const { workspaceId } = useParams()

  const didMount = useRef(false);

  const editQuestionModal = (questionId) => {
    return <EditQuestion
      editQuestionId={questionId}
      setCurrentModal={setCurrentModal}
      setQuestions={setQuestions}
    />
  }

  const columns = [
    {
      id: 'title',
      label: 'Question/Title',
      width: titleWidth,
      widthSetter: setTitleWidth,
      onClick: (id) => {
        setCurrentModal(editQuestionModal(id))
      },
    },
    {
      id: 'answer',
      label: 'Answer',
      width: answerWidth,
      widthSetter: setAnswerWidth,
    },
    {
      id: 'tags',
      label: 'Tags',
      width: tagsWidth,
      widthSetter: setTagsWidth,
    },
    {
      id: 'created_at',
      label: 'Created Date',
      width: createdDateWidth,
      widthSetter: setCreatedDateWidth,
    },
    {
      id: 'created_by',
      label: 'Created By',
      width: createdByWidth,
      widthSetter: setCreatedByWidth,
    },
  ]

  const canLoadMore = useMemo(() => {
    return total - questions.length > 0
  }, [total, questions])

  const tableRowHeight = useMemo(() => {
    return isMobile ? 135 : 40
  }, [isMobile])

  const loadMore = async () => {
    try{
    setIsLoadingMore(true)

    const searchParams = new URLSearchParams();
    searchParams.set('offset', questions.length)
    searchParams.set('order', `${sortBy} ${sortDirection}`)

    const response =  await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/questions?${searchParams.toString()}`,
    );

    const { questions: loadedQuestions, total: loadedTotal } = response.data

    setQuestions((prevQuestions) => [
      ...prevQuestions, ...loadedQuestions.map((question) => question.attributes)
    ])
    setTotal(loadedTotal)
    setIsLoadingMore(false)
    } catch(err) {
      console.error(err)
      setIsLoadingMore(false)
    }
  };

  const search = useCallback(async () => {
    try {
      setIsLoadingSearch(true)

      const searchParams = new URLSearchParams();
      searchParams.set('offset', 0)
      searchParams.set('order', `updated_at DESC`)
      if (selectedFilterTags.length > 0) {
        searchParams.set('tag_ids', selectedFilterTags.map((tag) => tag.value))
      }

      const response =  await axios.get(
        `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/questions?${searchParams.toString()}`,
      );

      setQuestions(response.data.questions.map((q) => q.attributes))
      setTotal(response.data.total)

      setIsLoadingSearch(false)
    } catch (err) {
      console.error(err)
      setIsLoadingSearch(false)
    }
  }, [selectedFilterTags, workspaceId])

  useEffect(() => {
    // Don't trigger on first render
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    search()
  }, [selectedFilterTags, search])

  return (
    <>
      <div className='QuestionsContainer'>
        {isLoadingSearch && 
          <div className='Container_loader'>
            <div className='Container_loaderSpinner' />
          </div>
        }
        <Toolbar
          openNewQuestionModal={() => {setCurrentModal(editQuestionModal())}}
          setQuestions={setQuestions}
          selectedFilterTags={selectedFilterTags}
          setSelectedFilterTags={setSelectedFilterTags}
          search={search}
          total={total}
        />
        <Table
          canLoadMore={canLoadMore}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
          columns={columns}
          rows={questions}
          tableRowHeight={tableRowHeight}
          isMobile={isMobile}
          customClass='Questions'
        />
      </div>
      <Modal 
        isModalOpen={currentModal}
        setCurrentModal={setCurrentModal}
      >
        {currentModal}
      </Modal>
    </>
  );
};