import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import SelectWrapper from './select-wrapper';

export default function Async_CreatableSelect({
  customClass, label, isMulti = false, error,
  cacheOptions = true, onChange, loadOptions,
  handleCreateOption, selectedOptions, placeholder,
  defaultOptions, noOptionsMessage
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onCreateOption = async (value) => {
    setIsLoading(true);

    await handleCreateOption(value)

    setIsLoading(false)
  }

  return (
    <SelectWrapper
      customClass={customClass}
      label={label}
      error={error}
    >
      <AsyncCreatableSelect
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        isMulti={isMulti}
        cacheOptions={cacheOptions}
        onChange={onChange} 
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        value={selectedOptions}
        placeholder={placeholder}
        classNamePrefix="react-select"
        defaultOptions={defaultOptions}
        noOptionsMessage={() => noOptionsMessage}
      />
    </SelectWrapper>
  );
};