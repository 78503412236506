import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import debounce from "lodash/debounce";
import TextInput from '../common/text-input';
import RadioInput from '../common/radio-input';
import AsyncSelect from '../common/select/async-select';
import Button from '../common/button';
import confirmDialog from '../common/confirm-dialog';

export default function EditQuiz(props) {
  const { workspaceId } = useParams();
  const endpointBase = `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}`
  const quizzesEndpoint =
    `${endpointBase}/quizzes`
  const tagsEndpoint =
    `${endpointBase}/tags`

  const [titleText, setTitleText] = useState('')
  const [questionCount, setQuestionCount] = useState(10)
  const [useAllQuestions, setUseAllQuestions] = useState(true)
  const [availableQuestionsCount, setAvailableQuestionsCount] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [editQuiz, setEditQuiz] = useState(null)

  const navigate = useNavigate()

  const searchTags = async (input) => {
    try {
      return axios.get(`${tagsEndpoint}?search_term=${input.toLowerCase()}`)
    } catch(err) {
      return null
    }
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      searchTags(inputText).then((options) => {
        const newOptions = !options ? [] :
          options.data.map((tag) => { return {
            value: tag.attributes.id, label: tag.attributes.name
          }})

        callback(newOptions)
      });
    }, 300),
    []
  );

  const onTagSelect = (updatedSelectedTags) => {
    setSelectedTags(updatedSelectedTags)
  }

  const fetchAvailableQuestionsCount = async () => {
    try{  
      const response =  await axios.post(
        `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/available_questions`,
        { tag_ids: selectedTags.map((tag) => tag.value) }
      );

      const total = response.data
  
      setAvailableQuestionsCount(total)

      if (total < questionCount && total !== 0) {
        setQuestionCount(total)
      }
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchAvailableQuestionsCount()
  }, [selectedTags])

  useEffect(() => {
    if (!useAllQuestions) {
      const inputElem = document.querySelector('.questionNumInput input')
      if (inputElem) { inputElem.focus() }
    } 
  }, [useAllQuestions])

  useEffect(() => {
    if (props.editQuizId) {
      props.setIsLoading(true)

      axios.get(`${quizzesEndpoint}/${props.editQuizId}`)
        .then((res) => {
          setEditQuiz(res.data.attributes)
          const { title, tags, question_count } = res.data.attributes

          setTitleText(title || '')
          setQuestionCount(question_count)
          setSelectedTags(tags.map((tag) => { return { value: tag.id, label: tag.name }}))

          if (question_count) {
            setQuestionCount(question_count)
            setUseAllQuestions(false)
          }

          props.setIsLoading(false)
        })
        .catch((err) => {
          console.error(err)

          props.setIsLoading(false)
        })
    }
  }, [])

  const handleDeleteQuiz = async () => {
    if (!(await confirmDialog({ confirmation: `Delete quiz?` }))) {
      return
    }

    try{
      props.setIsLoading(true)

      const response = await axios.delete(`${quizzesEndpoint}/${editQuiz.id}`)

      props.setQuizzes((prevQuizzes) =>
        prevQuizzes.filter((quiz) => quiz.id !== response.data.id)
      )
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)
      props.setIsLoading(false)
    }
  }

  const handleCreateQuiz = async () => {
    const quizData = {  title: titleText }

    if (selectedTags.length > 0) {
      quizData.tag_ids = selectedTags.map((tag) => tag.value)
    }

    if (!useAllQuestions) {
      quizData.question_count = questionCount
    }

    try {
      const response =
        await axios.post(`${endpointBase}/quizzes`, { quiz: quizData })

      props.setQuizzes((prevQuizzes) => [response.data.attributes, ...prevQuizzes])
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)

      props.setIsLoading(false)
    }
  }
  const handleEditQuiz = async () => {
    const editData = {}

    if (titleText !== editQuiz.title) { editData.title = titleText }
    if (useAllQuestions && editQuiz.question_count !== null) {
      editData.question_count = null
    } else if (!useAllQuestions && questionCount !== editQuiz.question_count) {
      editData.question_count = questionCount
    }

    const currentQuizTags = editQuiz.tags.map((tag) => tag.id).sort()
    const newQuizTags = selectedTags.map((tag) => tag.value).sort()

    if (currentQuizTags.join() !== newQuizTags.join()) {
      editData.tag_ids = newQuizTags
    }

    if (Object.entries(editData).length === 0) {
      props.setCurrentModal(null)
      return
    }

    try{
      props.setIsLoading(true)

      const response = await axios.patch(
        `${quizzesEndpoint}/${editQuiz.id}`,
        { quiz: editData }
      );

      props.setQuizzes((prevQuizzes) => {
        const quizIndex = prevQuizzes
          .findIndex(quiz => quiz.id === response.data.attributes.id)

        return [
          ...prevQuizzes.slice(0, quizIndex),
          response.data.attributes, 
          ...prevQuizzes.slice(quizIndex + 1)
        ]
      })
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)

      props.setIsLoading(false)
    }
  }
  const handleStartQuiz = async ({quizId}) => {
    try {
      props.setIsLoading(true)

      const activeQuizData = {} 
      if (quizId) {
        activeQuizData.quiz_id = quizId
      } else {
        activeQuizData.tag_ids = selectedTags.map((tag) => tag.value)
        activeQuizData.question_count = useAllQuestions ? null : questionCount
      }

      const response =
        await axios.post(`${endpointBase}/active_quizzes`, { active_quiz: activeQuizData })

      return navigate(`/workspaces/${workspaceId}/active-quiz/${response.data.active_quiz_id}`);
    } catch(err) {
      console.error(err)

      props.setIsLoading(false)
    }
  }
  const handleQuestionCountInput = (e) => {
    if (e.target.value === '' || e.target.value === '0') {
      setQuestionCount('')
      return
    }

    const value = parseInt(e.target.value.replace(/[^0-9]/g, ''))

    if (!isNaN(value)) {
      setQuestionCount(value > availableQuestionsCount ? availableQuestionsCount : value)
    }
  }

  return (
    <div className='EditQuizModal modal'>
      {props.loader}
      <h2>{`${editQuiz ? 'Edit' : 'Create'} quiz`}</h2>
      <TextInput
        onInput={e => setTitleText(e.target.value || '')}
        label='Title'
        placeholder='Enter Title e.g. Season 5 questions' 
        value={titleText}
      />
      <AsyncSelect
        label='Tags'
        isMulti={true}
        onChange={onTagSelect}
        loadOptions={loadOptions}
        selectedOptions={selectedTags}
        placeholder={`Select tags, e.g. "Season 3", "Milhouse", etc.`}
        noOptionsMessage='Type to search for tags'
      />
      <div className='EditQuizModal_availableQuestions'>
        <span className='EditQuizModal_availableQuestionsTitle'>Available Questions:</span>
        <span>{availableQuestionsCount !== null && availableQuestionsCount}</span>
      </div>
      <div className='EditQuizModal_questionNumContainer'>
        <label className='EditQuizModal_questionNumTitle'>Number of quiz questions</label>
        <div className='EditQuizModal_questionNumSelector'>
          <RadioInput
            checked={useAllQuestions}
            onChange={() => setUseAllQuestions(true)}
            value='taco'
            name='questionNumber'
            label='All Available Questions'
          />
          <RadioInput
            checked={!useAllQuestions}
            onChange={() => setUseAllQuestions(false)}
            name='questionNumber'
            label='Custom Number'
          />
          <TextInput
            customClass='questionNumInput'
            onInput={handleQuestionCountInput}
            onBlur={() => {
              if (questionCount === '') {
                setQuestionCount(10 > availableQuestionsCount ? availableQuestionsCount : 10)
              }
            }}
            value={questionCount}
            disabled={useAllQuestions}
          />
        </div>
      </div>
      <div className='Button_footer'>
        <Button
          disabled={availableQuestionsCount === 0}
          onClick={handleStartQuiz}
          text='Start'
        />
        {!editQuiz && <Button
          disabled={!titleText || availableQuestionsCount === 0} 
          onClick={handleStartQuiz}
          text='Start and save'
        />}
        <Button
          disabled={!titleText} 
          onClick={editQuiz ? handleEditQuiz : handleCreateQuiz}
          text='Save'
        />
        <Button
          text='Cancel'
          variant='cancel'
          onClick={() => props.setCurrentModal(null)}
        />
        {editQuiz && <Button
          text='Delete'
          variant='delete'
          onClick={() => { handleDeleteQuiz() }}
        />}
      </div>
    </div>
  );
};