import React, { useState, useEffect, useMemo, useRef } from 'react'
import axios from 'axios'
import Button from '../components/common/button';
import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import confirmDialog from '../components/common/confirm-dialog';
import '../styles/active-quiz.scss';

export default function ActiveQuiz({params}) {
  const baseUrl = process.env.REACT_APP_SERVER_API_URL

  const loaderData = useLoaderData();

  const { workspaceId, activeQuizId } = useParams()

  const [shouldShowAnswer, setShouldShowAnswer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState(loaderData.questions)
  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [currentQuestionId, setCurrentQuestionId] = useState(loaderData.currentQuestionId)
  const { orderedQuestionIds, quizId } = loaderData

  const navigate = useNavigate()

  const currentQuestion = useMemo(() => {
    return questions.find((question) => question.id === currentQuestionId)
  }, [currentQuestionId, questions])

  const nextQuestion = () => {
    const currentQuestionIndex = orderedQuestionIds.indexOf(currentQuestionId)

    setShouldShowAnswer(false)

    const newCurrentQuestionId = orderedQuestionIds[currentQuestionIndex + 1] || null
    setCurrentQuestionId(newCurrentQuestionId)

    axios.patch(`${baseUrl}workspaces/${workspaceId}/active_quizzes/${activeQuizId}`,
      { active_quiz: { current_question_id: newCurrentQuestionId } }
    ).then((res) => {

    }).catch((err) => {
      console.error(err)
    })
  }

  const exitQuiz = async () => {
    if (currentQuestionId) {
      const saveActiveQuiz = await confirmDialog({
        confirmation: `Save quiz for later?`,
        customOption: { value: 'no', text: `Don't Save`}
      })

      if (saveActiveQuiz === 'cancel') {
        return 
      } else if (saveActiveQuiz === 'no') {
        await axios.delete(`${baseUrl}workspaces/${workspaceId}/active_quizzes/${activeQuizId}`)
      }
    } else {
      await axios.delete(`${baseUrl}workspaces/${workspaceId}/active_quizzes/${activeQuizId}`)
    }

    return navigate(`/workspaces/${workspaceId}/quizzes`)
  }

  return (
    <div className='ActiveQuizContainer'>
      <div onClick={exitQuiz} className='ActiveQuiz_exitQuizButton'>
        <RiCloseLine />
      </div>
      <div className='ActiveQuiz_questionContainer'>
        {currentQuestionId === null ? 
          <div className='ActiveQuiz_quizEnd'>
            <div>Quiz Complete!</div>
            <div>
              
              {!quizId && <Button
                onClick={exitQuiz}
                text='Save quiz'
              />}
              <Button
                onClick={exitQuiz}
                text='Restart quiz'
              />
              <Button
                onClick={exitQuiz}
                text='Return to quizzes page'
              />
            </div>
          </div> :
          <>
            <div className='ActiveQuiz_questionTextSection'>
              <div className='ActiveQuiz_questionCounter'>
                Question {orderedQuestionIds.indexOf(currentQuestionId) + 1} of {questions.length}
              </div>
              <div className='ActiveQuiz_questionText'>{currentQuestion.question_text}</div>
            </div>
            <div className='ActiveQuiz_questionAnswerSection'>
              {shouldShowAnswer ?
                <>
                  <div className='ActiveQuiz_questionAnswerTitle'>Answer:</div>
                  <div className='ActiveQuiz_questionAnswer'>{currentQuestion.answer}</div>
                  <Button
                    onClick={nextQuestion}
                    text='Next Question'
                    customClass={'nextQuestionButton'}
                  />
                </> :
                <Button
                  onClick={() => {setShouldShowAnswer(true)}}
                  text='Show Answer'
                  customClass={'showAnswerButton'}
                />
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}