import axios from 'axios';
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";

export const loadInvitationSignUp = async ({params}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const invitationToken = searchParams.get('invitation_token')

  if (!invitationToken) {
    return redirect("/login?message=Invalid%20invitation%20token");
  }
  // const token = Cookies.get('trivia_token');

  // if (!token) {
  //   return redirect("/login");
  // }

  // try{
  //   return { questions: questions.map((q) => q.attributes), total }
  // } catch(err) {
  //   const status = err.response?.status
  //   if (status === 401 || status === 403) {
  //     Cookies.remove('trivia_token')
  //     return redirect("/login");
  //   }
  //   return err
  // }

  return invitationToken
  
};