import React, { useRef, useState, useEffect } from 'react';
import { RiEdit2Line } from "react-icons/ri";
import { useVirtualizer } from '@tanstack/react-virtual'
import '../../styles/table.scss';

const transparentGhostImage = document.createElement("img");   
transparentGhostImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export default function Table({
  columns, canLoadMore, 
  isLoadingMore, rows, 
  tableRowHeight, loadMore, 
  isMobile, customClass,
}) {
  const [resizeColumn, setResizeColumn] = useState(null)
  const columnResizePageX = useRef(null)
  const columnReszieStartingWidth = useRef(null)
  let columnResizeMoveTimer = null;

  const handleColumnResizeStart = (e) => {
    const { column } = e.target.dataset
    const index = parseInt(e.target.dataset.columnIndex)
    columnResizePageX.current = e.pageX
    columnReszieStartingWidth.current = columns[index].width
    setResizeColumn(column)

    e.dataTransfer.setDragImage(transparentGhostImage, 0, 0)
  }

  const handleColumnResizeEnd = (e) => { setResizeColumn(null) }

  const handleColumnResizeMove = (e) => {
    if (columnResizeMoveTimer) { return }

    const index = parseInt(e.target.dataset.columnIndex)

    columnResizeMoveTimer = setTimeout(() => {
      if (resizeColumn) {
        columns[index].widthSetter(
          columnReszieStartingWidth.current - (columnResizePageX.current - e.pageX)
        )
      }

      clearTimeout(columnResizeMoveTimer)
      columnResizeMoveTimer = null;
    }, 50)
  }

  const tableContainerRef = React.useRef()

  const rowVirtualizer = useVirtualizer({
    count: canLoadMore ? rows.length + 1 : rows.length,
    estimateSize: () => tableRowHeight || 40, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  })

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()
    if (!lastItem) {
      return
    }

    if (
      lastItem.index >= rows.length - 1 &&
      canLoadMore &&
      !isLoadingMore
    ) {
      loadMore()
    }
  }, [
    canLoadMore,
    isLoadingMore,
    loadMore,
    rows.length,
    rowVirtualizer.getVirtualItems(),
  ])

  return (
    <table ref={tableContainerRef} className={`Table_rowList ${customClass}`}>
      <thead>
        <tr>
          {columns.map((column, index) => {
            return (
              <th
                key={column.id}
                style={{
                  width: column.width + (index === 0 ? 1 : index === columns.length - 1 ? -1 : 0) 
                }}
                className={`${column.id}Column`}
              >
                {index > 0 &&
                  <div
                    data-column={columns[index - 1].id}
                    data-column-index={index - 1}
                    className='columnDragArea left'
                    onDragStart={handleColumnResizeStart}
                    onDragEnd={handleColumnResizeEnd}
                    onDrag={handleColumnResizeMove}
                    draggable={true}
                  />
                }
                <div>{column.label}</div>
                <div
                  data-column={column.id}
                  data-column-index={index}
                  className='columnDragArea right'
                  onDragStart={handleColumnResizeStart}
                  onDragEnd={handleColumnResizeEnd}
                  onDrag={handleColumnResizeMove}
                  draggable={true}
                />
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody style={{ height: `${rows.length * (tableRowHeight || 40)}px` }} >
        {rowVirtualizer.getVirtualItems().map(virtualRow => {
          const row = rows[virtualRow.index]
          const isLoaderRow = virtualRow.index > rows.length - 1
          
          return (
            <tr
              data-index={virtualRow.index}
              ref={node => rowVirtualizer.measureElement(node)}
              key={virtualRow.index}
              style={{
                transform: `translateY(${virtualRow.start}px)`,
                height: `${virtualRow.size}px`
              }}
            > 
              {isLoaderRow ? 
                <td className='loadingCell'>Loading</td> :
                <>
                  {columns.map((column) => {
                    switch(column.id) {
                      case 'title':
                        return (
                          <td key={column.id} className='titleCell' style={{ width: column.width }}>
                            {isMobile && <span className='mobileCellLabel'>{column.label}:</span>}
                            <span className='cellText'>{row.title}</span>
                            <div className='titleCell_editButtonWrapper'>
                              <button
                                onClick={() => column.onClick(row.id)}
                                className='titleCell_editButton'
                              >
                                <RiEdit2Line />
                              </button>
                            </div>
                          </td>
                        )
                      case 'tags':
                        return (
                          <td key={column.id} className='tagsCell' style={{ width: column.width }}>
                            {isMobile && <span className='mobileCellLabel'>{column.label}:</span>}
                            {row.tags.map(tag =>
                              <span className='tagsCell_tag' key={tag.id}>{tag.name}</span>
                            )}
                          </td>
                        )
                      default:
                        return (
                          <td key={column.id} style={{ width: column.width }}>
                            {isMobile && <span className='mobileCellLabel'>{column.label}:</span>}
                            <span className='cellText'>{row[column.id]}</span>
                          </td>
                        )
                    }
                  })}
                </>
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};