import React from 'react';

export default function Button(
  {text, onClick, disabled, variant = 'primary', customClass, children}
) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`Button ${variant} ${customClass || ''}`}
    >{text}{children}</button>
  );
};