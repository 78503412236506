import React from 'react';

export default function TextInput(props) {
  return (
    <div className={`TextInput_container ${props.customClass || ''}`}>
      {props.label &&
        <label className='TextInput_label'>{props.label}</label>
      }
      <input
        className='TextInput_input'
        placeholder={props.placeholder}
        value={props.value}
        onInput={props.onInput}
        onBlur={props.onBlur}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        type={props.type}
        maxLength={props.maxLength}
        name={props.name}
        autoComplete={props.autoComplete}
      />
      {props.error &&
        <label className='TextInput_error'>{props.error}</label>
      }
    </div>
  );
};