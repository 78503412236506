import React, { useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import Cookies from 'js-cookie';
import Modal from '../components/modal';
import AddWorkspace from '../components/modals/add-workspace';
import Button from '../components/common/button';
import { RiDeleteBin2Line, RiEdit2Line } from "react-icons/ri";
import DeleteEntity from '../components/modals/delete-entity';
import { Link } from "react-router-dom";
import '../styles/workspaces.scss'

export default function Workspaces() {

  const [workspaces, setWorkspaces] = useState(useLoaderData());
  const [currentModal, setCurrentModal] = useState(null)

  const addWorkspace = (workspace) => {
    setWorkspaces(workspaces => [...workspaces, workspace]);
  };

  const addWorkspaceModal = () => <AddWorkspace
    setCurrentModal={setCurrentModal}
    addWorkspace={addWorkspace}
  />

  return (
    <>
      <div className='WorkspacesContainer'>
        <div className='Workspaces_title'>Select workspace</div>
        <div className='Workspaces_workspaceList'>
          {workspaces.map((workspace) => {
            return <Link key={workspace.id} to={`/workspaces/${workspace.id}`}>
              {workspace.attributes.name}
            </Link>
          })}
        </div>
        <Button
          text="Create Workspace"
          onClick={() => setCurrentModal(addWorkspaceModal())}
        />
        
      </div>
      <Modal 
        isModalOpen={currentModal}
        setCurrentModal={setCurrentModal}
      >
        {currentModal}
      </Modal>
      </>

  );
};