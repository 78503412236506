import axios from 'axios';
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";

export const loadWorkspaceUser = async ({ params }) => {
  const token = Cookies.get('trivia_token');

  if (!token) {
    return redirect("/login");
  }

  try{
    const { workspaceId } = params
    const response =  await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/current_workspace_user`
    );

    return { ...response.data, workspaceId }
  } catch(err) {
    const status = err.response?.status
    if (status === 401 || status === 403) {
      Cookies.remove('trivia_token')
      return redirect("/login");
    }
    return err
  }
};