import React from 'react';
import { Outlet } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import Cookies from 'js-cookie';
import NavMenu from '../components/nav-menu';
import { redirect } from "react-router-dom";
import { useLoaderData, useLocation } from "react-router-dom";

export default function LoggedInRoot() {
  const currentWorkspaceUser = useLoaderData()

  const { pathname } = useLocation()
  return (
    <div className='RootContainer'>
      {!pathname.includes('active-quiz') && <NavMenu currentWorkspaceUser={currentWorkspaceUser}/>}
      <Outlet currentWorkspaceUser={currentWorkspaceUser}/>
    </div>
  );
};