import axios from 'axios';
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";

export const loadActiveQuiz = async ({ params }) => {
  const token = Cookies.get('trivia_token');

  if (!token) {
    return redirect("/login");
  }

  try{
    const { workspaceId, activeQuizId } = params

    const response =  await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/active_quizzes/${activeQuizId}`,
    );

    const { questions } = response.data.attributes
    let {
      ordered_question_ids: orderedQuestionIds,
      current_question_id: currentQuestionId,
      quiz_id: quizId
    } = response.data.attributes
    
    // If lengths dont match 
    if (questions.length !== orderedQuestionIds.length) {
      const currentQuestionIndex = orderedQuestionIds.indexOf(currentQuestionId)
      const questionFind = (question) => question.id === currentQuestionId
      let question = questions.find(questionFind)

      while (!question) {
        orderedQuestionIds.splice(currentQuestionIndex, 1)
        
        if (currentQuestionIndex + 1 > orderedQuestionIds.length) {
          currentQuestionId = null;
          break
        }

        currentQuestionId = orderedQuestionIds[currentQuestionIndex]
        question = questions.find(questionFind)
      }
    }
    
    return { questions, currentQuestionId, orderedQuestionIds, quizId }
  } catch(err) {
    const status = err.response?.status
    if (status === 401 || status === 403) {
      Cookies.remove('trivia_token')
      return redirect("/login");
    }
    return err
  }
};