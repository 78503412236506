import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Button from '../common/button';
import axios from 'axios';
import debounce from "lodash/debounce";
import AsyncSelect from '../common/select/async-select';

import '../../styles/filter-menu.scss';

export default function FilterMenu(props) {
  const { workspaceId } = useParams();
  const endpointBase = `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}`
  const tagsEndpoint =
    `${endpointBase}/tags`
  const [selectedTags, setSelectedTags] = useState(props.selectedFilterTags)

  const searchTags = async (input) => {
    try {
      return axios.get(`${tagsEndpoint}?search_term=${input.toLowerCase()}`)
    } catch(err) {
      return null
    }
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      searchTags(inputText).then((options) => {
        const newOptions = !options ? [] :
          options.data.map((tag) => { return {
            value: tag.attributes.id, label: tag.attributes.name
          }})

        callback(newOptions)
      });
    }, 300),
    []
  );

  const cancel = useCallback(() => {
    setSelectedTags(props.selectedFilterTags)
    props.setShouldShowFilterMenu(false)
  }, [props])

  const save = () => {
    props.setSelectedFilterTags(selectedTags)
    props.setShouldShowFilterMenu(false)
  }

  useEffect(() => {
    if (!props.shouldShowFilterMenu) { return }

    const outsideClickHandler = (e) => {
      const menuWrapper = document.querySelector('.FilterMenuWrapper')
  
      if (!e.composedPath().includes(menuWrapper)) {
        cancel()
      }
    }

    window.addEventListener('click', outsideClickHandler)
    return () => window.removeEventListener('click', outsideClickHandler)
  }, [ cancel, props.shouldShowFilterMenu])

  return (
    <div className='FilterMenuWrapper'>
      {props.children}
      {props.shouldShowFilterMenu && <div className='FilterMenuContainer'>
        <AsyncSelect
          label='Filter By Tags'
          isMulti={true}
          onChange={(updatedTags) => setSelectedTags(updatedTags)}
          loadOptions={loadOptions}
          selectedOptions={selectedTags}
          placeholder={`Select tags, e.g. "Season 3", "Milhouse", etc.`}
          noOptionsMessage='Type to search for tags'
        />
        <div className='Button_footer'>
          <Button
            onClick={save}
            text='Save'
          />
          <Button
            text='Cancel'
            variant='cancel'
            onClick={cancel}
          />
        </div>
      </div>}
    </div>
  );
};