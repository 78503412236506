import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  RiMenuFill as MenuIcon,
  RiQuestionLine as QuestionsIcon,
  RiListCheck3 as QuizzesIcon,
  RiSettings4Line as SettingsIcon,
  RiGroupLine as UsersIcon,
  RiLogoutBoxLine as LogOutIcon,
} from "react-icons/ri";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import '../styles/nav-menu.scss';

export default function NavMenu({ currentWorkspaceUser }) {
  const isMobile = useMediaQuery({ query: '(max-width: 430px)' })

  const { workspaceId, workspace_name: workspaceName } = currentWorkspaceUser

  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false)

  const { pathname } = useLocation()
  const currentRoute = pathname.split('/').slice(-1)[0]

  const navigate = useNavigate()

  const headerClick = () => {
    // if (!isMobile || isMobileNavExpanded) { return }
    console.log('triggered')
    setIsMobileNavExpanded(true)
  }

  useEffect(() => {
    if (!isMobileNavExpanded) { return }

    const clickHandler = (e) => {
      setIsMobileNavExpanded(false)
    }

    setTimeout(()=>{
      window.addEventListener('click', clickHandler)
    }, 50)
    return () => window.removeEventListener('click', clickHandler)
  }, [isMobileNavExpanded])

  return (
    <div className={`NavMenuContainer ${isMobileNavExpanded ? 'expanded' : ''}`}>
      <div onClick={headerClick} className='NavMenu_header'>
        <MenuIcon />
        <span className='NavMenu_headerTitle'>
          {workspaceName}
        </span>
      </div>
      <div className='NavMenu_list'>
        <a
          className={`NavMenu_listLink ${currentRoute === 'questions' ? 'selected' :''}`}
          href={`/workspaces/${workspaceId}/questions`}
        >
          <QuestionsIcon />
          <span>Questions</span>
        </a>
        <a
          className={`NavMenu_listLink ${currentRoute === 'quizzes' ? 'selected' :''}`}
          href={`/workspaces/${workspaceId}/quizzes`}
        >
          <QuizzesIcon />
          <span>Quizzes</span>
        </a>
        {currentWorkspaceUser.is_admin && <a
          className={`NavMenu_listLink ComingSoon ${currentRoute === 'users' ? 'selected' :''}`}
          // href={`/workspaces/${workspaceId}/users`}
        >
          <UsersIcon />
          <span>Users</span>
        </a>}
        <a
          className={
            `NavMenu_listLink ComingSoon ${currentRoute === 'settings' ? 'selected' :''}`
          }
          // href={`/workspaces/${workspaceId}/settings`}
        >
          <SettingsIcon />
          <span>Settings</span>
        </a>
        <button
          className={`NavMenu_listLink`}
          onClick={() => {
            Cookies.remove('trivia_token')
            return navigate("/login");
          }}
        >
          <LogOutIcon />
          <span>Log out</span>
        </button>
      </div>
    </div>
  );
};