import React from 'react';
import Button from '../common/button';
import TextInput from '../common/text-input';

import '../../styles/toolbar.scss';

export default function Toolbar(props) {
  return (
    <div className='ToolbarContainer'>
      <Button
        text="New Quiz"
        onClick={()=>{props.openNewQuizModal()}}
      />
      <div className='ToolbarContainer_searchWrapper'>
        <TextInput
          placeholder="Enter text to search"
        />
      </div>
    </div>
  );
};