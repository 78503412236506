import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import TextInput from '../common/text-input';
import TextArea from '../common/text-area';
import Button from '../common/button';
import AsyncCreatableSelect from '../common/select/async-creatable-select';
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import confirmDialog from '../common/confirm-dialog';

export default function EditQuestion(props) {

  const { workspaceId } = useParams();
  const questionsEndpoint =
    `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/questions`
  const tagsEndpoint =
    `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/tags`

  const [titleText, setTitleText] = useState("")
  const [questionText, setQuestionText] = useState("")
  const [answerText, setAnswerText] = useState("")
  const [selectedTags, setSelectedTags] = useState([])
  const [editQuestion, setEditQuestion] = useState(null)

  const searchTags = async (input) => {
    try {
      return axios.get(`${tagsEndpoint}?search_term=${input.toLowerCase()}`)
    } catch(err) {
      return null
    }
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      searchTags(inputText).then((options) => {
        const newOptions = !options ? [] :
          options.data.map((tag) => { return {
            value: tag.attributes.id, label: tag.attributes.name
          }})

        callback(newOptions)
      });
    }, 300),
    []
  );

  const onTagSelect = (updatedSelectedTags) => {
    setSelectedTags(updatedSelectedTags)
  }

  const onTagCreate = async (tagName) => {
    if (await confirmDialog({ confirmation: `Create tag "${tagName}"?` }) === 'cancel') {
      return
    }

    try {
      const response = await axios.post(tagsEndpoint, {
        name: tagName
      })

      const { id, name } = response.data.attributes
      setSelectedTags((prevSelectedTags) => [...prevSelectedTags, { value: id, label: name }])
      
    } catch(err) {
      console.error(err)
    }
  }

  const handleCreateQuestion = async () => {
    try{
      props.setIsLoading(true)

      const createData = {
        title: titleText,
        question_text: questionText,
        answer: answerText,
        tag_ids: selectedTags.map((tag) => tag.value),
      }

      const response =  await axios.post(questionsEndpoint,
        { question: createData}
      );

      props.setQuestions((prevQuestions) => [...prevQuestions, response.data.attributes])
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)

      props.setIsLoading(false)
    }
  }

  const handleEditQuestion = async () => {
    const editData = {}

    if (titleText !== editQuestion.title) { editData.title = titleText }
    if (questionText !== editQuestion.question_text) {editData.question_text = questionText }
    if (answerText !== editQuestion.answer) { editData.answer = answerText }

    const currentQuestionTags = editQuestion.tags.map((tag) => tag.id).sort()
    const newQuestionTags = selectedTags.map((tag) => tag.value).sort()

    if (currentQuestionTags.join() !== newQuestionTags.join()) {
      editData.tag_ids = newQuestionTags
    }
    
    try{
      props.setIsLoading(true)

      const response = await axios.patch(
        `${questionsEndpoint}/${editQuestion.id}`,
        { question: editData }
      );

      props.setQuestions((prevQuestions) => {
        const questionIndex = prevQuestions
          .findIndex(question => question.id === response.data.attributes.id)

        return [
          ...prevQuestions.slice(0, questionIndex),
          response.data.attributes, 
          ...prevQuestions.slice(questionIndex + 1)
        ]
      })
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)

      props.setIsLoading(false)
    }
  }

  const handleDeleteQuestion = async () => {
    if (await confirmDialog({ confirmation: `Delete question?` }) === 'cancel') {
      return
    }

    try{
      props.setIsLoading(true)

      const response = await axios.delete(`${questionsEndpoint}/${editQuestion.id}`)

      props.setQuestions((prevQuestions) =>
        prevQuestions.filter((question) => question.id !== response.data.id)
      )
      props.setCurrentModal(null)

      props.setIsLoading(false)
    } catch(err) {
      console.error(err)
      props.setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.editQuestionId) {
      props.setIsLoading(true)

      axios.get(`${questionsEndpoint}/${props.editQuestionId}`)
        .then((res) => {
          setEditQuestion(res.data.attributes)
          const { title,  question_text, answer, tags } = res.data.attributes

          setTitleText(title || '')
          setQuestionText(question_text)
          setAnswerText(answer)
          setSelectedTags(tags.map((tag) => { return { value: tag.id, label: tag.name }}))

          props.setIsLoading(false)
        })
        .catch((err) => {
          console.error(err)

          props.setIsLoading(false)
        })
    }
  }, [])

  return (
    <div className='EditQuestionModal modal'>
      {props.loader}
      <h2>{`${editQuestion ? 'Edit' : 'Create'} question`}</h2>
      <TextInput
        onInput={e => setTitleText(e.target.value || '')}
        label='Title'
        placeholder='Optional, will use question text as title if left empty' 
        value={titleText}
      />
      <TextArea
        onInput={e => setQuestionText(e.target.value || '')}
        label='Question'
        placeholder={`Enter question, e.g. "What is Homer Simpson's middle name?"`}
        value={questionText}
      />
      <TextArea
        onInput={e => setAnswerText(e.target.value || '')}
        label='Answer'
        placeholder={`Enter answer, e.g. "Jay"`}
        value={answerText}
      />
      <AsyncCreatableSelect
        label='Tags'
        isMulti={true}
        onChange={onTagSelect}
        loadOptions={loadOptions}
        handleCreateOption={onTagCreate}
        selectedOptions={selectedTags}
        placeholder={`Select tags, e.g. "Season 3", "Milhouse", etc.`}
        noOptionsMessage='Type to search for tags'
      />
      <div className='Button_footer'>
        <Button
          disabled={!questionText && !answerText} 
          onClick={editQuestion ? handleEditQuestion : handleCreateQuestion}
          text='Save'
        />
        <Button
          text='Cancel'
          variant='cancel'
          onClick={() => props.setCurrentModal(null)}
        />
        {editQuestion && <Button
          text='Delete'
          variant='delete'
          onClick={() => { handleDeleteQuestion() }}
        />}
      </div>
      
    </div>
  );
};