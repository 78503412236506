import React, { useCallback, useState } from 'react';
import Button from '../common/button';
import TextInput from '../common/text-input';

import '../../styles/toolbar.scss';
import FilterMenu from './filter-menu';

export default function Toolbar(props) {
  const [shouldShowFilterMenu, setShouldShowFilterMenu] = useState(false)
  return (
    <div className='ToolbarContainer Questions'>
      <Button
        text="New Question"
        onClick={()=>{props.openNewQuestionModal()}}
      />
      <div className='ToolbarContainer_searchWrapper'>
        <TextInput
          placeholder="Enter text to search"
          customClass="ComingSoon"
        />
        {/* <button>Search Settings</button> */}
      </div>

      <FilterMenu
        selectedFilterTags={props.selectedFilterTags}
        setSelectedFilterTags={props.setSelectedFilterTags}
        setShouldShowFilterMenu={setShouldShowFilterMenu}
        shouldShowFilterMenu={shouldShowFilterMenu}
        search={props.search}
      >
        <Button
          text="Filter Results"
          onClick={() => {setShouldShowFilterMenu(!shouldShowFilterMenu)}}
          customClass='ToolbarContainer_filterButton'
        >
          {props.selectedFilterTags.length > 0 &&
            <span className='ToolbarContainer_filterCounter'>
              {props.selectedFilterTags.length}
            </span>
          }
        </Button>
      </FilterMenu>
      <div className='ToolbarContainer_questionsCount'>
        Displaying {props.total} question{props.total === 1 ? '' : 's'}
      </div>
    </div>
  );
};