import React, { useState } from 'react';
import axios from 'axios';
import TextInput from '../common/text-input';
import Button from '../common/button';

export default function AddWorkspace(props) {

  const [workspaceName, setWorkspaceName] = useState("")

  const createWorkspace = async () => {
    try{
      const response =  await axios.post(`${process.env.REACT_APP_SERVER_API_URL}workspaces`, {
        name: workspaceName
      });

      props.addWorkspace(response.data)
      props.setCurrentModal(null)
    } catch(err) {
      console.error(err)
    }
  }

  return (
    <div className='EditWorkspaceModal modal'>
      <h2>Create Workspace</h2>
      <TextInput
        onInput={e => setWorkspaceName(e.target.value || '')}
        placeholder='Enter workspace name'
        maxLength={90}
      />
      <div className='Button_footer'>
        <Button
          disabled={!workspaceName} 
          onClick={createWorkspace}
          text='Save'
        />
        <Button
          text='Cancel'
          variant='cancel'
          onClick={() => props.setCurrentModal(null)}
        />
      </div>
    </div>
  );
};