import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import '../../styles/confirm-dialog.scss';
import Button from './button';

const ConfirmDialog = ({show, proceed, confirmation, options, customOption}) => (
  // <div onHide={() => proceed(false)} show={show}>
  show && <div className='ConfirmDialog_container'>
    <div className='ConfirmDialog'>
      <p className='ConfirmDialog_title'>{confirmation}</p>
      <div className='Button_footer'>
        <Button
          onClick={() => proceed('yes')}
          text='Confirm'
        />
        {customOption && <Button
          onClick={() => proceed(customOption.value)}
          text={customOption.text}
        />}
        <Button
          text='Cancel'
          variant='cancel'
          onClick={() => proceed('cancel')}
        />
      </div>
    </div>
  </div>
)

export default createConfirmation(confirmable(ConfirmDialog));