import axios from 'axios';
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";

export const loadQuestions = async ({params}) => {
  const token = Cookies.get('trivia_token');

  if (!token) {
    return redirect("/login");
  }

  try{
    const searchParams = new URLSearchParams();
    searchParams.set('offset', 0)
    searchParams.set('order', `updated_at DESC`)

    const { workspaceId } = params
    const response =  await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/questions?${searchParams.toString()}`,
    );

    const { questions, total } = response.data

    return { questions: questions.map((q) => q.attributes), total }
  } catch(err) {
    const status = err.response?.status
    if (status === 401 || status === 403) {
      Cookies.remove('trivia_token')
      return redirect("/login");
    }
    return err
  }
  
};