import React, { useState } from 'react';
import TextInput from '../components/common/text-input';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../styles/login.scss';

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const navigate = useNavigate()

  const onLoginClick = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}login`, {
        user: {
          email,
          password,
        }
      });

      Cookies.set('trivia_token', response.headers.authorization, { expires: 30, secure: true });

      axios.defaults.headers.common['Authorization'] = response.headers.authorization

      // const workspaces = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/workspaces`)
      navigate('/workspaces')
    } catch(err) {
      console.error(err)
    }
  }

  return (
    <div className='LoginContainer'>
      <div className='LoginForm'>
        <TextInput
          value={email}
          onInput={e => {setEmail(e.target.value || '')}}
          placeholder='Enter Email'
          type='email'
          name='email'
          autocomplete='on'
        />
        <TextInput
          value={password}
          onInput={e => setPassword(e.target.value || '')}
          placeholder='Enter Password'
          type='password'
          name='password'
          autoComplete='on'
        />
        <button
          onClick={onLoginClick}
          disabled={!email || !password}
          className='LoginForm_button'
        >Log In</button>
      </div>
    </div>
  );
};