import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './routes/login';
import InvitationSignUp from './routes/invitation-sign-up';
import Workspaces from './routes/workspaces';
import LoggedInRoot from './routes/logged-in-root';
import Questions from './routes/questions';
import ActiveQuiz from './routes/active-quiz';
import Quizzes from './routes/quizzes';
import Settings from './routes/settings';
import { checkIfLoggedIn, checkIfLoggedOut } from './hooks/useAuth';
import { loadWorkspaceUser } from './hooks/loggedInRootLoader';
import { loadWorkspaces } from './hooks/workspacesLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import './styles/index.scss';
import { loadQuestions } from './hooks/questionsLoader';
import { loadQuizzes } from './hooks/quizzesLoader';
import { loadActiveQuiz } from './hooks/activeQuizLoader';
import { loadInvitationSignUp } from './hooks/invitationSignUpLoader';

axios.interceptors.request.use(
  config => {
    const token = Cookies.get('trivia_token')
    if (token) {
      config.headers['Authorization'] = token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    loader: () => {
      return redirect('/login')
    },
  },
  {
    path: 'invitation/accept',
    element: <InvitationSignUp />,
    loader: loadInvitationSignUp,
  },
  {
    path: 'login',
    element: <Login />,
    loader: checkIfLoggedOut,
  },
  {
    path: 'workspaces',
    element: <Workspaces />,
    loader: loadWorkspaces,
  },
  {
    path: 'workspaces/:workspaceId',
    element: <LoggedInRoot />,
    loader: loadWorkspaceUser,
    children: [
      {
        index: true,
        element: <Questions />,
        loader: loadQuestions,
      },
      {
        path: 'questions',
        element: <Questions />,
        loader: loadQuestions,
      },
      {
        path: 'quizzes',
        element: <Quizzes />,
        loader: loadQuizzes,
      },
      {
        path: 'active-quiz/:activeQuizId',
        element: <ActiveQuiz />,
        loader: loadActiveQuiz,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ]
  },
])
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
