import React from 'react';
import AsyncSelect from 'react-select/async';
import SelectWrapper from './select-wrapper';

export default function Async_Select({
  customClass, label, isMulti = false, error,
  cacheOptions = true, onChange, loadOptions,
  selectedOptions, placeholder, defaultOptions,
  noOptionsMessage
}) {
  return (
    <SelectWrapper
      customClass={customClass}
      label={label}
      error={error}
    >
      <AsyncSelect
        isClearable
        isMulti={isMulti}
        cacheOptions={cacheOptions}
        onChange={onChange} 
        loadOptions={loadOptions}
        value={selectedOptions}
        placeholder={placeholder}
        classNamePrefix="react-select"
        defaultOptions={defaultOptions}
        noOptionsMessage={() => noOptionsMessage}
      />
    </SelectWrapper>
  );
};