import React from 'react';

export default function RadioInput(props) {
  return (
    <label className={`RadioInput_container ${props.customClass || ''}`}>
      <span className='RadioInput_label'>{props.label}</span>
      <input
        className='RadioInput_input'
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        checked={props.checked}
        type='radio'
      />
      <span className='RadioInput_dot' />
    </label>
  );
};