import React from 'react';
import '../../../styles/select.scss';

export default function SelectWrapper({
  customClass, label, error, children
}) {
  return (
    <div className={`Select_containter ${customClass || ''}`}>
      {label &&
        <label className='Select_label'>{label}</label>
      }
      {children}
      {error &&
        <label className='Select_error'>{error}</label>
      }
    </div>
  );
};