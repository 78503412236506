import React, { useState } from 'react';

export default function Modal(props) {
  const [isLoading, setIsLoading] = useState(false)

  const loader =
    (isLoading &&
      <div className='Container_loader'>
        <div className='Container_loaderSpinner' />
      </div>
    )

  return (
    <>
    {props.isModalOpen &&
      <div className='ModalContainer'>
        {React.cloneElement(props.children, {isLoading, setIsLoading, loader})}
      </div>
    }
    </>
  );
};