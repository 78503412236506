import React, { useEffect, useRef } from 'react';

export default function TextArea({onInput, value, label, placeholder, error}) {
  useEffect(() => {
    const elem = textareaRef.current

    // Make textarea taller to fit text content if needed
    if (elem.scrollHeight > elem.clientHeight) {
      elem.style.height = `${elem.scrollHeight}px`
    }
  }, [value])

  const textareaRef = useRef()

  return (
    <div className='TextArea_containter'>
      {label &&
        <label className='TextArea_label'>{label}</label>
      }
      <textarea
        className='TextArea_textarea'
        maxLength={2000}
        placeholder={placeholder}
        value={value}
        onInput={onInput}
        ref={textareaRef}
      />
      {error &&
        <label className='TextArea_error'>{error}</label>
      }
    </div>
  );
};