import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    // setUser(data);
    Cookies.set('trivia_token', data, { expires: 30000, secure: true });
    navigate("/workspaces");
  };

  // call this function to sign out logged in user
  const logout = () => {
    // setUser(null);
    Cookies.remove('trivia_token');
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const checkIfLoggedIn = () => {
  const token = Cookies.get('trivia_token');

  if (!token) {
    return redirect("/login");
  }

  return null;
};

export const checkIfLoggedOut = () => {
  const token = Cookies.get('trivia_token');

  if (token) {
    return redirect("/workspaces");
  }

  return null;
};