import React, { useState } from 'react';
import TextInput from '../components/common/text-input';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate, useLoaderData } from "react-router-dom";

export default function InvitationSignUp() {
  const token = useLoaderData();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const navigate = useNavigate()

  const onSignUpClick = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}invitation`, {
        user: {
          first_name: firstName,
          last_name: lastName,
          user_name: userName,
          password,
          password_confirmation: passwordConfirmation,
          invitation_token: token,
        }
      });

      navigate('/login?message=You%20have%20been%20registered!%20Sign%20in')
    } catch(err) {
      console.error(err)
    }
  }

  return (
    <div className='LoginContainer'>
      <div className='LoginForm'>
        <TextInput
          value={firstName}
          onInput={e => {setFirstName(e.target.value || '')}}
          placeholder='Enter First Name'
          label='First Name'
          name='first name'
          autoComplete='on'
          maxLength={40}
        />
        <TextInput
          value={lastName}
          onInput={e => {setLastName(e.target.value || '')}}
          placeholder='Enter Last Name (Optional)'
          label='Last Name'
          name='last name'
          autoComplete='on'
          maxLength={40}
        />
        <TextInput
          value={userName}
          onInput={e => {setUserName(e.target.value || '')}}
          placeholder='Enter User Name'
          label='Username'
          name='Username'
          maxLength={40}
        />
        <TextInput
          value={password}
          onInput={e => setPassword(e.target.value || '')}
          placeholder='Enter Password'
          label='Password'
          name='password'
          type='password'
          autoComplete='on'
          maxLength={40}
        />
        <TextInput
          value={passwordConfirmation}
          onInput={e => setPasswordConfirmation(e.target.value || '')}
          placeholder='Enter Password Again'
          label='Confirm Password'
          name='password'
          type='password'
          maxLength={40}
        />
        <button
          onClick={onSignUpClick}
          disabled={!password || !firstName || !userName}
          className='LoginForm_button'
        >Sign Up</button>
      </div>
    </div>
  );
};