import React, { useState, useMemo, useEffect } from 'react';
import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import Toolbar from '../components/quizzes/toolbar';
import EditQuiz from '../components/modals/edit-quiz';
import Modal from '../components/modal';
import Table from '../components/common/table';
import axios from 'axios';
import Button from '../components/common/button';
import { useMediaQuery } from 'react-responsive'
import '../styles/quizzes.scss';

export default function Quizzes() {
  const isMobile = useMediaQuery({ query: '(max-width: 430px)' })

  const { quizzes: loaderQuizzes, total: loaderTotal } = useLoaderData();
  const [quizzes, setQuizzes] = useState(loaderQuizzes);
  const [total, setTotal] = useState(loaderTotal)
  const [currentModal, setCurrentModal] = useState(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [activeQuiz, setActiveQuiz] = useState(null);

  const columnWidths = JSON.parse(window.localStorage.getItem('trivaTownColumnWidths')) || {}
  const [titleWidth, setTitleWidth] = useState(isMobile ? '100%' : columnWidths.title || 450)
  const [answerWidth, setAnswerWidth] = useState(isMobile ? '100%' : columnWidths.answer|| 300)
  const [tagsWidth, setTagsWidth] = useState(isMobile ? '100%' : columnWidths.tags|| 200)
  const [createdDateWidth, setCreatedDateWidth] = useState(isMobile ? '100%' : columnWidths.createdDate|| 100)
  const [createdByWidth, setCreatedByWidth] = useState(isMobile ? '100%' : columnWidths.createdBy|| 200)

  const tableContainerRef = React.useRef(null)
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const editQuizModal = (quizId) => {
    return <EditQuiz
      editQuizId={quizId}
      setCurrentModal={setCurrentModal}
      setQuizzes={setQuizzes}
    />
  }

  const resumeActiveQuiz = () => {
    return navigate(`/workspaces/${workspaceId}/active-quiz/${activeQuiz.id}`);
  }

  const columns = [
    {
      id: 'title',
      label: 'Title',
      width: titleWidth,
      widthSetter: setTitleWidth,
      onClick: (id) => {
        setCurrentModal(editQuizModal(id))
      },
    },
    {
      id: 'tags',
      label: 'Tags',
      width: tagsWidth,
      widthSetter: setTagsWidth,
    },
    {
      id: 'created_at',
      label: 'Created Date',
      width: createdDateWidth,
      widthSetter: setCreatedDateWidth,
    },
    {
      id: 'created_by',
      label: 'Created By',
      width: createdByWidth,
      widthSetter: setCreatedByWidth,
    },
  ]

  const loadMore = () => {}

  const canLoadMore = useMemo(() => {
    return total - quizzes.length > 0
  }, [total, quizzes])

  const tableRowHeight = useMemo(() => {
    return isMobile ? 112 : 40
  }, [isMobile])

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}workspaces/${workspaceId}/active_quizzes`,
    ).then((res) => {
      if (res.data?.attributes) {
        setActiveQuiz(res.data.attributes)
      }
    }).catch((err) => {
      console.error(err)
    });
  }, [])

  return (
    <>
      <div className='QuizzesContainer'>
        {activeQuiz && 
          <div className='Quizzes_activeQuizBanner'>
            You have quiz in progress
            <Button 
              onClick={resumeActiveQuiz}
              text='Resume'
            />
          </div>
        }
        <Toolbar
          openNewQuizModal={() => {setCurrentModal(editQuizModal())}}
          setQuestions={setQuizzes}
        />
        <Table
          canLoadMore={canLoadMore}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
          columns={columns}
          rows={quizzes}
          tableRowHeight={tableRowHeight}
          isMobile={isMobile}
          customClass='Quizzes'
        />
      </div>
      <Modal
        isModalOpen={currentModal}
        setCurrentModal={setCurrentModal}
      >
        {currentModal}
      </Modal>
    </>
  );
};