import axios from 'axios';
import Cookies from 'js-cookie';
import { redirect } from "react-router-dom";

export const loadWorkspaces = async () => {
  const token = Cookies.get('trivia_token');

  if (!token) {
    return redirect("/login");
  }
  // axios.defaults.headers.common['Authorization'] = token
  try{
    const response =  await axios.get(`${process.env.REACT_APP_SERVER_API_URL}workspaces`);

    return response.data
  } catch(err) {
    if (err.response.status === 401) {
      Cookies.remove('trivia_token')
      return redirect("/login");
    }
  }
  
};